/* src/App.css */
body {
  min-height: 100vh;
  height: 100vh;
  background: radial-gradient(ellipse at right, #e7e2e5, transparent),
    radial-gradient(ellipse at left, #dccadd, transparent 80%);
}

.App {
  min-height: 100vh;
  height: 100vh;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;

}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  font-style: italic;
  font-weight: 300;
  color: #2b2e2d;
  padding-top: 25vh;
}

/* Additional styling for responsiveness */
@media screen and (max-width: 768px) {
  .App-header {
    font-size: calc(6px + 1vmin);
  }
}

.panel {
  width: 65vw;
  height: 65vh;
  margin: auto;
  padding: 0;
  margin-top: 15vh;
}

.bubble {
  position: absolute;
  top: 1vh;
  right: 1vw;
  width: 45vw;
  height: 45vw;
  background: radial-gradient(circle at 75% 30%,
      #a1e9a1 5px,
      #007d00 8%,
      #161226 75%,
      #5c4089 100%);
  border-radius: 50%;
  backdrop-filter: blur(200px);
  box-shadow: -15px 15px 30px black;
  opacity: 0.89;
  overflow: hidden;
}


.bubble2 {
  position: absolute;
  top: 1vh;
  right: 1vw;
  width: 45vw;
  height: 45vw;
  background: radial-gradient(circle at 75% 30%,
      #fff 5px,
      #a16bfe 8%,
      #161226 75%,
      #5c4089 100%);
  border-radius: 50%;
  backdrop-filter: blur(200px);
  box-shadow: -15px 15px 30px black;
  opacity: 1;
  overflow: hidden;
}

.bottom-mid {
  top: 60vh;
  left: 10vw;
  width: 25vw;
  height: 25vw;
}

.bottom-corner {
  top: 70vh;
  left: 15vw;
  width: 12vw;
  height: 12vw;
}

.glass {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.35);

  -webkit-box-shadow: -6px 6px 5px 0px rgba(79, 64, 133, 1);
  -moz-box-shadow: -6px 6px 5px 0px rgba(79, 64, 133, 1);
  box-shadow: -3px 3px 3px 0px #aba1a9;
}

.glass2 {
  background: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.35);

  -webkit-box-shadow: -6px 6px 5px 0px rgba(79, 64, 133, 1);
  -moz-box-shadow: -6px 6px 5px 0px rgba(79, 64, 133, 1);
  box-shadow: -3px 3px 3px 0px #aba1a9;

  padding: 16px !important;
  width: 50% !important;
  margin: auto;
  margin-top: 25px;
  ;
}

.teal {
  color: #009688 !important;
  padding: 15px;

}